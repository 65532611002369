<template>
    <div class="page-product-info g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>产品资讯</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="product-info-list">
                <div class="item cursorPointer" v-for="(item,index) in productList" @click="goCPdetail(item)" :key="index">
                    <div class="con">
                        <div class="pic">
                            <img :src="item.pictureUrl || defaultImg" alt="">
                        </div>
                        <div class="title">{{item.title}}</div>
                    </div>
                </div>
            </div>

            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="[20, 50, 100]"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>


        </div>
    </div>
</template>

<script>
import {productPage} from "@/api/home";

export default {
    name: "ProductInfo",
    data() {
        return {
            current:1,
            size:20,
            total:0,
            productList:[],

        }
    },
    mounted() {
        this.getproductPage()
    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getproductPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getproductPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getproductPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getproductPage();
        },
        goCPdetail(item){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/ProductInfoDetail',query: {id:item.id}})
        },

        getproductPage(){ // 获取品牌列表
            let param={
                current:this.current,
                size:this.size
            }
            productPage(param).then((res) => {
                if (res.data.code==200) {
                    this.productList=res.data.data.records
                    this.total=Number(res.data.data.total)
                }

            })
        },

    },
}
</script>

<style scoped>

</style>
